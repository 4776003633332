import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../components/scss/app.scss"

const AboutKalotiPage = () => (
    <Layout>
    <SEO title="Oral Cancer Awareness Month | The Smile Stylist" 
    description="Oral cancer can be cured if detected early enough. Find out how you can detect oral cancer at home just a few minutes." 
    keywords="do i have, oral cancer, teeth canada, dental clinics, oakville, toronto, mississauga, canada" />
    <main class="site-content prindex" role="main">
    <section id="main-top-img" class="blog hidden-xs">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 sec-title wow animated fadeInDown">
            <div class="blog-news-art">
              <h1>Oral Cancer Awareness Month</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="main-white">
      <div class="container">
        <div class="row">
          <div class="col-sm-8 wow animated fadeInRight">
            <h2 className="bl__h2">5 ways to detect oral cancer at home</h2>
            <p>As we have heard time and again, the best defence against cancer is detecting it early on. This is no different when it comes to mouth and throat cancer, and we want to ensure that you are not affected by this life-changing disease. As April is Oral Cancer Awareness Month, it is about time that we reviewed the five ways to detect oral cancer at home! These are not home remedies, nor a replacement for check-ups with your dentist, but they do allow you to do your own due diligence to ensure you can spot if something is off with your own mouth.</p>
            <img className="bl__img" src={ require("../../images/desktop/cancer-img-1.png" )} alt="Oral Cancer Common Symptoms" />
            <p>
            <h2>1. The tongue and gauze method</h2>
            <p>Extend your tongue as far as it can go and examine the sides and underside of your tongue. Ensure to take a look at white and red patches or any lumps that seem out of place. This is the most common area for cancer to develop in non-smokers and a quick check will ensure you are on top of things.</p>
            </p>
            <p>
            <h2>2. Lip and Cheek Roll with a single finger</h2>
            <p>Take your finger and run it around your mouth and checks. You are feeling for lumps or bumps and looking for white or red splotches.</p>
            </p>
            <p>
            <h2>3. Double-Digit Probe</h2>
            <p>This one is a little more complicated but is a great way to ensure you get a total check. Examine the floor of your mouth from top to bottom and look for lumps or splotches of white or red.</p>
            </p>
            <p>
            <h2>4. Tonsil Check, or otherwise called the ‘AHHHHHHHH” method</h2>
            <p>Just like you have done a million times at the doctor, the tonsil check is an essential part of any at home check. You are looking for tonsil symmetry while checking for enlargement, redness or unusual lumps in the throat.</p>
            </p>
            <p>
            <h2>5. Neck Caress</h2>
            <p>It is not uncommon for mouth cancer to hide further down the throat, and a quick neck caress is always important. Check for enlarged lymph nodes as this may indicate an illness, or something a little worse.</p>
            </p>
            <img className="bl__img" src={ require("../../images/desktop/4a.png" )} alt="Tongue Surface" />
            <img className="bl__img" src={ require("../../images/desktop/1a.png" )} alt=" Lip Area" />
            <img className="bl__img" src={ require("../../images/desktop/3a.png" )} alt="Tonsillar Area"/> 
            <img className="bl__img" src={ require("../../images/desktop/2a.png" )} alt="Mucosa" />
            <p>Generally, people have a low-risk for mouth cancer, but certain lifestyle decision can yield a higher risk. This includes smoking, using smokeless tobacco and heavy drinking. As well, if you have a history of mouth cavities within your family or your own personal dental records, you are at a higher risk of developing this kind of cancer. With over 30,000 new cases diagnosed each year, oral cancer has quickly become the sixth deadliest cancer in the world. In fact, it claims the life of a Canadian every single hour.</p>
            <p>Besides these great little at home detection tricks, one of the best ways to prevent oral cancer is visiting your dentist regularly. We are proud to work with our clients to prevent cancer, and it is part of the reason that we got into the business. In fact, we are now offering Free VELscope Oral Cancer Screening for every single one of our patients. This is the kind of service you can expect from us, and now is the time to book your appointment with our team and enjoy a free VELscope Oral Cancer Screening on the house!</p>
          </div>
          <div class="col-sm-4 blog-right">
            <a href="/virtual-smile-makeover"><img alt="Virtual Smile Consultation" src={ require("../../images/desktop/get-virtual-smile.png" )} class="img-responsive wow animated zoomIn" data-wow-delay="0.3s" /></a>
          </div>
        </div>
      </div>
    </section>
  </main>
  </Layout>
)

export default AboutKalotiPage